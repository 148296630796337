<template>
  <div class="container">
    <div class="pt-2 pb-2">
      <form class="sniperForm" method="post">
        <input
          name="${_csrf.parameterName!''}"
          value="${_csrf.token!''}"
          type="hidden"
        />
        <input name="id" type="hidden" v-model="id" />
        <ul class="mt-4 ul-no">
          <li
            v-for="(q, k) in questionShow"
            :key="k"
            :id="q.code ? 'q-' + q.code : ''"
            :class="q.class"
            v-show="q.hide === 0"
          >
            <el-card class="box-card">
              <div
                slot="header"
                class="clearfix"
                :class="!q.input_type ? 'bg' : ''"
              >
                <survey-title :q="q"></survey-title>
              </div>

              <ul
                :class="'q-c'"
                style="list-style: upper-alpha"
                v-if="q.input_type === 'radio' && q.content_hidden === 0"
              >
                <li
                  v-for="(o, k) in q.input_options.items"
                  :key="k + o.code"
                  :id="'o-' + q.code + '-' + o.code"
                >
                  <survey-li-radio
                    :q="q"
                    :o="o"
                    :answer="answer"
                    @switch_target="switch_target"
                  >
                  </survey-li-radio>

                  <div
                    v-for="(b, inx) in questionAppend(q.code + '-' + o.code)"
                    v-show="b.hide === 0"
                    :key="inx"
                  >
                    <survey-title :q="b"></survey-title>

                    <ul class="q-c mb-3 ul-ua" v-if="b.input_type === 'radio'">
                      <survey-radio
                        :q="b"
                        :answer="answer"
                        :items="b.input_options.items"
                        @switch_target="switch_target"
                      >
                      </survey-radio>
                    </ul>

                    <ul
                      class="q-c mb-3 ul-ua"
                      v-else-if="b.input_type === 'checkbox'"
                    >
                      <survey-checkbox
                        :q="b"
                        :answer="answer"
                        :items="b.input_options.items"
                        @switch_target="switch_target"
                      >
                      </survey-checkbox>
                    </ul>

                    <div
                      class="q-c mb-3"
                      v-else-if="b.input_type === 'textarea'"
                    >
                      <survey-textarea
                        :q="b"
                        :answer="answer"
                      ></survey-textarea>
                    </div>

                    <div class="q-c mb-3" v-else-if="b.input_type === 'number'">
                      <survey-input-number
                        :q="b"
                        :answer="answer"
                      ></survey-input-number>
                    </div>

                    <div class="q-c mb-3" v-else>
                      <survey-input :q="b" :answer="answer"></survey-input>
                    </div>
                  </div>
                </li>
              </ul>

              <ul
                class="q-c ul-ua"
                v-else-if="
                  q.input_type === 'checkbox' && q.content_hidden === 0
                "
              >
                <li v-for="o in q.input_options.items" :key="k + o.code">
                  <survey-li-checkbox
                    :q="q"
                    :o="o"
                    :answer="answer"
                    @switch_target="switch_target"
                  >
                  </survey-li-checkbox>

                  <div
                    v-for="(b, inx) in questionAppend(q.code + '-' + o.value)"
                    v-show="b.hide !== 1"
                    :key="inx"
                  >
                    <survey-title :q="b"></survey-title>

                    <ul class="q-c mb-3 ul-ua" v-if="b.input_type === 'radio'">
                      <survey-radio
                        :q="b"
                        :answer="answer"
                        :items="b.input_options.items"
                        @switch_target="switch_target"
                      >
                      </survey-radio>
                    </ul>

                    <ul
                      class="q-c mb-3 ul-ua"
                      v-else-if="b.input_type === 'checkbox'"
                    >
                      <survey-checkbox
                        :q="b"
                        :answer="answer"
                        :items="b.input_options.items"
                        @switch_target="switch_target"
                      >
                      </survey-checkbox>
                    </ul>

                    <div
                      class="q-c mb-3"
                      v-else-if="b.input_type === 'textarea'"
                    >
                      <survey-textarea
                        :q="b"
                        :answer="answer"
                      ></survey-textarea>
                    </div>

                    <div class="q-c mb-3" v-else-if="b.input_type === 'number'">
                      <survey-input-number
                        :q="b"
                        :answer="answer"
                      ></survey-input-number>
                    </div>

                    <div class="q-c mb-3" v-else>
                      <survey-input :q="b" :answer="answer"></survey-input>
                    </div>
                  </div>
                </li>
              </ul>

              <div
                v-else-if="q.input_type === 'select' && q.content_hidden === 0"
              >
                <survey-select
                  :q="q"
                  :answer="answer"
                  :options="dataFromParent(q.input_options)"
                  @switch_select="switch_select"
                >
                </survey-select>
              </div>

              <div
                class="q-c mb-3"
                v-else-if="
                  q.input_type === 'textarea' && q.content_hidden === 0
                "
              >
                <survey-textarea :q="q" :answer="answer"></survey-textarea>
              </div>

              <div
                class="q-c mb-3"
                v-else-if="q.input_type === 'number' && q.content_hidden === 0"
              >
                <survey-input-number
                  :q="q"
                  :answer="answer"
                ></survey-input-number>
              </div>

              <div class="q-c mb-3" v-else-if="q.content_hidden === 0">
                <survey-input
                  :q="q"
                  :answer="answer"
                  @do_input_max="do_input_max"
                ></survey-input>
              </div>
            </el-card>
          </li>
        </ul>
        <!-- <div
          class="btn-group text-center"
          role="group"
          aria-label="Basic example"
          style="width: 100%"
        >
          <button
            type="button"
            class="btn btn-secondary"
            v-show="buttonShow"
            @click="saveCache"
          >
            暂存
          </button>
          <button type="submit" class="btn btn-success" v-show="buttonShow">
            提交问卷
          </button>
        </div> -->
      </form>
    </div>
  </div>
</template>

<script>
import { tempSave, getTempSave, delTempSave } from "@/api/wenjuanWeChat";
import "../../../../wxapp/public/ajax_post_mp_view.js";
import Vue from "vue";
// import layer from "vue-layer";
import layer from "layui-layer";
import "vue-layer/lib/vue-layer.css";
import request from "@/utils/request.js";

import $ from "@/assets/jquery-3.6.0.min";

import vueSurveyRadio from "@/components/wenjuan/radio.vue";
import vueSurveyTitle from "@/components/wenjuan/wxsurveyTitle.vue";
import vueSurveyLiRadio from "@/components/wenjuan/surveyLiRadio.vue";
import vueSurveyCheckbox from "@/components/wenjuan/surveyCheckbox.vue";
import vueSurveyInput from "@/components/wenjuan/surveyInput.vue";
import surveyInputNumber from "@/components/wenjuan/surveyInputNumber.vue";
import surveyLiCheckbox from "@/components/wenjuan/surveyLiCheckbox.vue";
import surveyTextarea from "@/components/wenjuan/surveyTextarea.vue";
import surveySelect from "@/components/wenjuan/surveySelect.vue";

import { getQuestions } from "@/api/wenjuan";
import { templateGetIdApi } from "@/api/templateApi.js";
// //问卷id
// const id = ${id}
// //企业id
// const cid = ${cid}
// const uid = ${uid}
//问卷id
const id = "";
//企业id
const cid = "";
const uid = "";
//抽奖小程序信息
const appid = "wxc97e107842dd83a7";
const path = "pages/draw/draw";
export default {
  components: {
    "survey-radio": vueSurveyRadio,
    "survey-title": vueSurveyTitle,
    "survey-li-radio": vueSurveyLiRadio,
    "survey-checkbox": vueSurveyCheckbox,
    "survey-input": vueSurveyInput,
    "survey-input-number": surveyInputNumber,
    "survey-li-checkbox": surveyLiCheckbox,
    "survey-textarea": surveyTextarea,
    "survey-select": surveySelect,
  },
  data() {
    return {
      title: "问卷填写",
      baseUrl: "https://miniapp.wenjuan.sdcom.gov.cn",
      debug: false,
      id: id,
      uid: 0,
      uscc: "",
      cid: 0,
      appid: "",
      path: "",
      survey: {},
      //当前参与企业
      companyTemp: {},
      //问卷前后情况
      surveyAround: {},
      question: [],
      //为了增加问题和选项获取的速度，定义两个变量
      questionMap: {},
      optionMap: {},
      //历史答案
      historyAnswer: {},
      historyType: 0,
      answer: {},
      //此变量是为了出发选项事件二舍，其实也可以直接调用事件函数也可以
      answerEvent: 0,
      //测试
      inputEvent: {
        e: {},
        q: {},
        o: {},
        fun: "",
      },
      //确认只执行一次
      answerFirst: true,
      //确认只执行一次
      questionFirst: true,
      localId: "form_" + id,
      buttonShow: false,
    };
  },
  watch: {
    cid(val, oldVal) {
      //加载参与企业信息//暂时只用到uscc
      console.log("watch-cid");
      //this.getCompany(this.$route.query.cid);
    },
    companyTemp(val, oldVal) {
      console.log("watch-companyTemp");
      //只有企业信息完毕之后才能拉去问卷
      //this.getSurvey();
    },
    //没出意外基本只会跑一次
    survey(val, oldVal) {
      console.log("watch-survey");
    //   document.title = val.wjdoTitle;
    },
    question: {
      handler(val, oldVal) {
        console.log("watch-question");

        //首次初始化,放在这里是因为需要questionMap optionMap变量不为空
        if (this.answerFirst || this.answer.length === 0) {
          this.answerFirst = false;
          //设置答案默认选项
          this.answerInit();

          //检查前后问卷
        //   this.getSurveyAround();

          //加载暂存,前后顺序决定谁的数据为准
        //   this.loadCache();

          //触发问题事件
        //   this.answerEventPlus();
        }
        if (this.answer.length === 0) {
          layer.msg("数据初始化失败");
        }

        //给问题增加一个索引
        var index = 0;
        for (var i in this.question) {
          var q = this.question[i];
          //只有显示标题的设置索引
          if (q.show_index === 1) {
            index++;
            q.index = index;
          }
        }

        //上面每次改动都要修改，下面不需要，所以需要循环两边
        if (this.questionFirst || this.questionMap.length === 0) {
          this.questionFirst = false;
          for (const i in this.question) {
            const q = this.question[i];
            //缓存处理
            this.questionMap[q.code] = q;
            if (q.input_options) {
              for (var oi in q.input_options.items) {
                var o = q.input_options.items[oi];
                //this.optionMap[q.code + o.code] = o;
                //q.code  题目code
                //o.code  选项code
                this.$set(this.optionMap, q.code + o.code, o);
              }
            }
          }
        }

        if (this.answer.questionMap === 0) {
          layer.msg("问题加载失败");
        }
      },
      deep: true,
    },
    surveyAround(val, oldVal) {
      console.log("watch-surveyAround");
      //前置问卷
      if (val.prevHas) {
        this.toSurveyPrev();
      } else {
        //加载历史数据
        //this.survey.wjdoIdHistory 旧版历史数据读取，单词每次问卷填写都会改题，可能导致部分题目不一样
        var _this = this;

        //加载已经写过当前问卷的上一次填写结果
        if (this.surveyAround.currentHas) {
          layer.confirm(
            "是否加载已填写问卷答题?",
            {
              icon: 3,
              btn: ["确认", "取消"],
            },
            function (index) {
              //完全载入
              _this.loadHistory(_this.surveyAround.current, 0);
              layer.close(index);
            },
            function (index) {
              console.log("222");
              layer.close(index);
            }
          );
        } else if (this.surveyAround.historyHas) {
          layer.confirm(
            "是否加载历史问卷答题",
            {
              icon: 3,
              btn: ["确认", "取消"],
            },
            function (index) {
              _this.loadHistory(_this.surveyAround.history, 1);
              layer.close(index);
            },
            function (index) {
              layer.close(index);
            }
          );
        }
      }
    },
    historyAnswer(val, oldVal) {
      for (var i in val) {
        var ans = val[i];
        //非本页题目不要了
        //console.log(ans)
        // console.log(ans.name, _this.answer[ans.name], _this.answer[ans.name].length)
        //赋值原则
        //值赋值那些本体该有的题库，此变量默认是 表示可以添加任意题
        var ansNameHas = true;
        if (this.historyType === 1) {
          //如果是历史数据可能题库不一样，只导入有的题库，判断当前题库的题目是否和历史题库题目对应
          ansNameHas = !!this.answer.hasOwnProperty(ans.name);
        }

        //题目存在和原题目答案为空才能赋值
        //&& this.answer[ans.name] && this.answer[ans.name].length === 0
        if (ansNameHas) {
          if (Array.isArray(this.answer[ans.name])) {
            if (ans.values && ans.values.length > 0) {
              // this.answer[ans.name] = ans.values;
              this.$set(this.answer, ans.name, ans.values);
            }
          } else {
            if (ans.value) {
              // this.answer[ans.name] = ans.value;
              this.$set(this.answer, ans.name, ans.value);
            }
          }
        }
      }

      //因为answerEvent变量不能再次操作所以写了一个函数去操作
      this.answerEventPlus();
    },
    answer: {
      handler(val, oldVal) {
        console.log("watch-answer");
        //循环处理问题，根据答案变化当问题答案不为空显示问题
        for (var i in this.answer) {
          var q = this.getQuestion(i);
          if (q && q.input_type) {
            if (q.input_type === "checkbox") {
              if (this.answer[i].length > 0) {
                //console.log("1", i, q.input_type, this.answer[i], this.answer[i].length)
                q.hide = 0;
              }
            } else if (this.answer[i] && this.answer[i] !== "") {
              //console.log("2", i, q.input_type, this.answer[i], this.answer[i].length)
              q.hide = 0;
            }
          }
        }
      },
      deep: true,
    },
    answerEvent(val, oldVal) {
      //加载事件
      console.log("watch-answerEvent");
      this.doAnswerEvent();
    },
    inputEvent: {
      handler(val, oldVal) {
        console.log("watch-inputEvent");
      },
      deep: true,
    },
  },

  computed: {
    //读取显示的问题，获取一级问题列表
    questionShow() {
      var qq = [];
      for (var index in this.question) {
        var q = this.question[index];
        //过滤隐藏或者属于附属问题的
        if (q.hide !== 1 && !q.append_to) {
          qq.push(q);
        }
        //复选框时出现错误数据修正（如值为false或null 值不存在但有题目的）
        if (q.input_type === "checkbox") {
          if (this.answer[q.code] !== undefined && !this.answer[q.code]) {
            this.answer[q.code] = [];
          } else if (this.answer[q.code] === undefined) {
            this.answer[q.code] = [];
          }
        }
      }
      return qq;
    },
  },
  async created() {
    this.cid = 0;
    this.uid = 0;
    this.id = this.$route.query.id;
    // if (this.$route.query.buttonShow) {
    //   this.buttonShow= true;
    // } else {
    //   this.buttonShow = false;
    // }
    // await this.getCompany(this.cid);
    setTimeout(async () => {
                await this.getSurvey();
              }, 500);
    // document.title = this.title;
  },
  async mounted() {
   

    //抽奖小程序id,这2个参数正式环境是必须的
    if (typeof appid !== "undefined") {
      this.appid = appid;
    }
    //抽奖小程序地址
    if (typeof path !== "undefined") {
      this.path = path;
    }
  },
  methods: {
    
    async answerInit() {
      //答案初始化
      for (var i in this.question) {
        var q = this.question[i];
        if (q.input_type === "checkbox") {
          if (q.input_default_value && q.input_default_value !== "") {
            this.$set(this.answer, q.code, [q.input_default_value]);
          } else {
            this.$set(this.answer, q.code, []);
          }
        } else {
          //放在这里可能companyTemp 为空
          if (q.input_default_from && this.companyTemp) {
            this.$set(
              this.answer,
              q.code,
              this.companyTemp[q.input_default_from]
            );
          } else {
            if (q.input_default_value && q.input_default_value !== "") {
              this.$set(this.answer, q.code, q.input_default_value);
            } else {
              this.$set(this.answer, q.code, "");
            }
          }
        }
      }
    },
    answerEventPlus() {
      //触发问题事件
      //此变量不能写在watch里面
      this.answerEvent++;
    },
    doAnswerEvent() {
      //答案初始化之后的事件
      for (var i in this.answer) {
        var q = this.getQuestion(i);
        if (q && q.input_type) {
          if (q.input_type === "checkbox") {
            if (this.answer[i].length > 0) {
              //指向选中的任务
              var ansVal = this.answer[i];
              for (var ii in ansVal) {
                //执行选中操作
                var o = this.getOption(q.code, ansVal[ii]);
                if (o && o.switch_target) {
                  this.doChange(o.switch_target, o.switch_need);
                }
              }
            }
          } else if (this.answer[i] && this.answer[i] !== "") {
            //执行选中操作
            var o = this.getOption(q.code, this.answer[i]);
            if (o && o.switch_target) {
              this.doChange(o.switch_target, o.switch_need);
            }
          }
        }
      }
    },
   
    questionAppend(code) {
      var qq = [];
      for (var index in this.question) {
        var q = this.question[index];
        if (q.append_to === code) {
          qq.push(q);
        }
      }
      return qq;
    },
    //检查是否包含
    getQuestion(code) {
      return this.questionMap[code] ? this.questionMap[code] : {};
    },
    getOption(qcode, ocode) {
      return this.optionMap[qcode + ocode] ? this.optionMap[qcode + ocode] : {};
    },
   
    async getSurvey() {
      var _this = this;
      var params = {
        id: this.$route.query.id,
      };
      var res = await templateGetIdApi(params);
      _this.survey = res.data;
      _this.question = res.data.survey.questions;
    },
    dataFromParent(option) {
      //根据父级选项确认自己的选项范围
      if (option.parent) {
        //匹配父级选中的值/一般是select
        var pVal = this.answer[option.parent];
        if (!pVal) {
          return [];
        }
        var items = [];
        for (var d in option.items) {
          var dd = option.items[d];
          if (dd.pcode === pVal) {
            items.push(dd);
          }
        }
        return items;
      } else {
        return option.items;
      }
    },
    //select专用
    switch_select(e, q) {
      var target = e.target;
      var val = $(target).val();
      var o = this.getOption(q.code, val);
      if (o && o.switch_target) {
        this.doChange(o.switch_target, o.switch_need);
      }
    },
    //点击事件
    switch_target(e, q, o) {

      var target = e.target;
      var checked = false;
      if ($(target).is(":checked")) {
        checked = true;
      }

      //console.log("checked", checked)
      //console.log(o.group100, o.group100_val)

      if (checked && o.group100) {
        var groupCheck = this.doGroupCheck(o.group100);
        if (!groupCheck) {
          //禁止一下操作
          layer.msg("超出选择范围,需要和相关题目选择占比不能超过100%");
          if (q.input_type === "checkbox") {
            var index = this.answer[q.code].indexOf(o.value);
            if (index > -1) {
              this.answer[q.code].splice(index, 1);
            }
          } else {
            //清除当前答案
            this.answer[q.code] = "";
          }
          return false;
        }
      }

      if (checked && o.switch_target) {
        this.doChange(o.switch_target, o.switch_need);
      }

      //只适用于CheckBox,无选中操作
      if (!checked && q.input_type === "checkbox" && o.off_switch_target) {
        this.doChange(o.off_switch_target);
      }
    },
    doGroupCheck(group100) {
      //检测选项值，选择之和不能超过100
      //判断多个问题的选中的值不能超过100
      //console.log("group100", group100)
      if (group100) {
        var g = group100.split("@")[0].split("|");
        var gStart = 0;
        var o;
        for (var gg in g) {
          var qCode = g[gg];
          var qq = this.getQuestion(qCode);
          var vv = this.answer[qCode];
          //console.log("vv", vv)
          if (qq.input_type === "checkbox") {
            //查询选中的值,多选
            //计算所有选中值 group100_val 的和 并且不能大于100
            if (vv.length > 0) {
              for (var vvv in vv) {
                o = this.getOption(qCode, vv[vvv]);
                gStart += parseInt(o.group100_val);
              }
            }
          } else if (qq.input_type === "radio") {
            //单选
            if (vv) {
              o = this.getOption(qCode, vv);
              //console.log(qCode, vv, o.group100_val)
              gStart += parseInt(o.group100_val);
            }
          }
        }

        if (gStart >= 100) {
          return false;
        }
      }
      return true;
    },

    do_input_max(q) {
      //检查几个text加起来的和不能超过一个值
      // console.log('do_input_max1', q)
      if (q.input_group_name && q.input_group_max) {
        var g = q.input_group_name.split("|");
        var gl = g.length;
        var gr = [];
        for (var gg in g) {
          var qq = this.getQuestion(g[gg]);
          var vv = this.answer[g[gg]];
          if (qq.input_type === "text" || qq.input_type === "number") {
            if (vv) {
              gr.push(parseFloat(vv).toFixed(2));
            }
          }
        }

        if (gr.length === gl) {
          var input_group_max = parseFloat(q.input_group_max);
          var gStart = 0;
          for (var i in gr) {
            gStart += parseFloat(gr[i]);
          }

          if (gStart !== input_group_max) {
            layer.msg("需要和相关题目填写值加起来等于" + q.input_group_max);
            this.answer[q.code] = "";
            return false;
          }
        }
      }
      return true;
    },
    doChange(target, need) {
      //分解need，need的含义就是想要激活一个问题需要其他问题选择对应的值
      var nnMap = {};
      if (need) {
        var nn = need.split(",");
        for (var kk in nn) {
          var nnn = nn[kk].split("|");
          nnMap[nnn[0]] = {
            q: nnn[1],
            o: nnn[2],
          };
        }
      }

      //console.log(nnMap)
      var st = target.split(",");
      for (var k in st) {
        var vv = st[k].split("|");
        var vvv = vv[1];
        var qq = this.getQuestion(vv[0]);

        if (!qq || !qq.code) {
          continue;
        }
        //默认放行,文件说用在激活上面
        var n = true;
        if (qq && qq.code && n) {
          if (nnMap[qq.code]) {
            //获取要去问题数据判断是多选还是单选
            var nq = this.getQuestion(nnMap[qq.code].q);
            var nqd = nnMap[qq.code];
            if (nq.input_type === "checkbox") {
              //判断对应的值是否选中
              if (this.answer[nqd.q].indexOf(nqd.o) === -1) {
                n = false;
              }
            } else if (
              nq.input_type === "radio" ||
              nq.input_type === "select"
            ) {
              if (this.answer[nqd.q] !== nqd.o) {
                n = false;
              }
            }
          }

          //取消选中,处理问题根据最优先匹配处理原则
          if (vvv === "##") {
            this.disabledQuestion(qq, vvv);
          } else if (vvv === "#") {
            this.disabledQuestion(qq, vvv);
          } else if (vvv.indexOf("##") > -1) {
            this.disabledOption(qq, vvv);
          } else if (n && vvv === "**") {
            this.enabledQuestion(qq, vvv);
          } else if (n && vvv === "*") {
            this.enabledQuestion(qq, vvv);
          } else if (vvv.indexOf("**") > -1) {
            this.enabledOption(qq, vvv);
          } else if (vvv.indexOf("!") > -1) {
            this.setNotValue(qq, vvv);
          } else {
            this.setValue(qq, vvv);
          }
        }

      }
    },
    //禁用选项
    disabledOption(q, v) {
      //处理##开头
      var oCode = v.substr(2, v.length - 2);
      var o = this.getOption(q.code, oCode);
      o.disabled = true;

    },
    enabledOption(q, v) {
      //处理##开头
      var oCode = v.substr(2, v.length - 2);
      var o = this.getOption(q.code, oCode);
      o.disabled = false;

    },
    //原版隐藏问题有两种，一种直接隐藏，另外一种只显示划线标题不显示内容
    disabledQuestion(q, type) {
      //处理#隐藏，##禁用
      //禁用问题
      if (type === "##") {
        //禁用问题一般是加横岗
        //只针对显示的问题，默认隐藏的不管
        if (q.hide === 0) {
          q.hide = 0;
          q.title_hidden = 0;
          q.content_hidden = 1;
        }
      } else {
        q.hide = 1;
      }

      if (q.input_type === "checkbox") {
        this.answer[q.code] = [];
      } else {
        this.answer[q.code] = "";
      }
    },
    enabledQuestion(q, type) {
      //激活问题 处理 *显示， **激活
      if (type === "**") {
        //激活问题不是显示问题，所以继续隐藏
        //激活问题不是显示问题，默认该无变化
        //只处理显示的问题，不显示的保持默认值
        if (q.hide === 0) {
          q.hide = 0;
          q.title_hidden = 0;
          q.content_hidden = 0;
        }
      } else {
        q.hide = 0;
      }

    },
    setNotValue(q, vvv) {
      //处理!开头 //不允许使用此选项
      var value = vvv.substr(1, vvv.length - 1);
      //select默认当单选使用
      if (q.input_type === "checkbox") {
        if (this.answer[q.code]) {
          var index = this.answer[q.code].indexOf(value);
          if (index > -1) {
            this.answer[q.code].splice(index, 1);
          }
        }
      } else {
        if (this.answer[q.code] && this.answer[q.code].indexOf(value) > -1) {
          //清空答案或者替换相关字符串这里清空答案，没有管text等文本内容
          this.answer[q.code] = "";
        }
      }

    },
    setValue(q, val) {
      //处理没有任何开头一般都是最后处理/select默认当单选使用
      if (q.input_type === "checkbox") {
        if (this.answer[q.code]) {
          var index = this.answer[q.code].indexOf(val);
          if (index === -1) {
            this.answer[q.code].push(val);
          }
        }
      } else {
        this.answer[q.code] = val;
      }

    },
  },
};
</script>
<style scoped>
.container {
  height: 100%;
}
[v-cloak] {
  display: none;
}

.handler {
  cursor: pointer;
}

.b-b {
  border-bottom: #ddd solid 1px;
}

.ul-ua {
  /* list-style: upper-alpha inside; */
  list-style: upper-alpha;
}

.ul-no {
  list-style: none;
  padding-left: 0;
}

.ul-no > li {
  margin-bottom: 1em;
}

.sub-question {
  padding-left: 2em;
}
#app {
  height: 100%;
  background-color: #fff !important;
}
</style>
